// components
import { Article, HeadsetMic, Info, ManageSearch } from "@mui/icons-material";

export const ACCESS = [
  { id: 0, access: "admin" },
  { id: 1, access: "editor" },
  { id: 2, access: "rep" },
  { id: 3, access: "none" },
];

export const PRODUCTION_PATH = "";

export const VERSION = "v1.4.4";
export const ENV = { production: "prod", development: "dev" };

// this is a SVG icon that I created in illustrator
// it was used in the MUI page until it was replaced by the split view icon from material ui
// import using...
// import { treeSVG } from "../constants/constants";
// then...
// embed using the following code:
// <SvgIcon fontSize="large" color="primary">
// {treeSVG}
// </SvgIcon>

export const treeSVG = (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="176.98" height="176.92" viewBox="0 0 176.98 176.92">
    <path fill="currentColor" d="m86.01,102.01h90.93v21.23h-90.93v-21.23Z" />
    <path fill="currentColor" d="m176.98,150.36v21.12h-90.95v-21.12h90.95Z" />
    <path fill="currentColor" d="m176.94,74.95h-90.93v-21.15h90.93v21.15Z" />
    <path
      fill="currentColor"
      d="m32.29,155.6c0,3.37.08,6.27-.07,9.16-.03.56-1,1.5-1.56,1.52-3.15.13-6.32.18-9.47,0-5.59-.33-10.04-4.94-10.37-10.53-.04-.66-.03-1.33-.03-1.99,0-34.16,0-68.33,0-102.49,0-.83,0-1.65,0-2.71h10.65v10.31h10.61v10.91h-10.41v37.49h10.63c0,3.4.07,6.54-.08,9.67-.02.42-1.08,1.12-1.69,1.14-2.89.12-5.8.05-8.87.05v37.48h10.65Z"
    />
    <path fill="currentColor" d="m75.11,145.06v31.86h-31.98v-31.86h31.98Z" />
    <path fill="currentColor" d="m75.08,80.34h-31.98v-31.87h31.98v31.87Z" />
    <path fill="currentColor" d="m43,128.63v-31.81h32.03v31.81h-32.03Z" />
    <path
      fill="currentColor"
      d="m16.23,0c2.49,0,4.98-.02,7.47,0,5.05.04,8.36,3.02,8.54,8.06.18,5.31.18,10.63,0,15.93-.16,4.96-3.33,7.99-8.25,8.09-5.23.1-10.46.11-15.68,0C3.2,32,.04,28.66.02,23.54c-.02-5.06-.03-10.13,0-15.19C.05,3.44,3.39.08,8.27.02c2.66-.03,5.31,0,7.97,0Z"
    />
  </svg>
);

export const fields = [
  {
    id: "phone_script",
    icon: <HeadsetMic />,
    title: "Phone script",
    color: "#eda8b2",
    guestEdit: false,
    supported: true,
  },
  {
    id: "page_content",
    icon: <Article />,
    title: "Scheduling advice",
    color: "#ac89a7",
    guestEdit: false,
    supported: true,
  },
  {
    id: "additional_info",
    icon: <Info />,
    title: "Additional Info",
    color: "#79acde",
    guestEdit: false,
    supported: true,
  },
  {
    id: "keywords",
    icon: <ManageSearch />,
    title: "Search keywords",
    color: "#FEAD77",
    guestEdit: true,
    supported: true,
  },
];

export const markdownExamples = [
  {
    name: "Bold Text",
    code: `This is **bold**  <br/>
           This is __also bold__  <br/>
           Use as**teris**ks for mid word bolding`,
    markdown: "This is **bold**  \nThis is __also bold__  \nUse as**teris**ks for mid word bolding",
    supported: true,
  },
  {
    name: "Italic Text",
    code: `This is *italic*  <br/>
           This is _also italic_  <br/>
           Use as*teris*ks for mid word italics`,
    markdown: "This is *italic*  \nThis is _also italic_  \nUse as*teris*ks for mid word italics",
    supported: true,
  },
  {
    name: "Italics/Bold Text",
    code: `This is ***both***  <br/>
           This is ___also both___  <br/>
           Any combo for __*both*__  <br/>
           Use as***teris***ks for mid word emphasis`,
    markdown:
      "This is ***both***  \nThis is ___also both___  \nAny combo for __*both*__  \nUse as***teris***ks for mid word emphasis",
    supported: true,
  },
  {
    name: "Strikethrough",
    code: `This is ~~crossed out~~`,
    markdown: "This is ~~crossed out~~",
    supported: true,
  },
  {
    name: "Highlight",
    code: `This is ==highlighted==`,
    markdown: "This is ==highlighted==",
    supported: false,
  },
  {
    name: "Subscript",
    code: `H~2~O`,
    markdown: "H~2~O",
    supported: false,
  },
  {
    name: "Superscript",
    code: `x^2^`,
    markdown: "x^2^",
    supported: false,
  },
  {
    name: "Emoji",
    code: `I am happy :smile:`,
    markdown: "I am happy :smile:",
    supported: false,
  },
  {
    name: "Block Quote",
    code: `> This is a quote</br>
           ></br>
           >> Nested quote</br>
           </br>
           > {caution} blockquote with yellow caution stripe</br>
           </br>
           > {urgent} blockquote with red caution stripe`,
    markdown:
      "> This is a quote\n>\n>> Nested quote\n\n  \n\n> {caution} blockquote with yellow caution stripe\n\n  \n\n> {urgent} blockquote with red caution stripe",
    supported: true,
  },
  {
    name: "Ordered List",
    code: `1. First<br/>
           2. Second<br/>
                1. Numbers<br/>
                1. Don't matter`,
    markdown: "1. First\n2. Second\n    1. Numbers\n    1. Don't matter",
    supported: true,
  },
  {
    name: "Unordered List",
    code: `* Asterisks<br/>
           * List<br/>
                * Nested<br/>
           + Can also use plus sign<br/>
           - Can also use dash`,

    markdown: "* Asterisks\n* List\n    * Nested\n\n+ Can also use plus sign\n- Can also use dash",
    supported: true,
  },
  {
    name: "Checklist",
    code: `- [ ] Must include space<br />
           - [x] Completed`,
    markdown: "- [ ] Must include space\n- [x] Completed",
    supported: true,
  },
  {
    name: "Tables",
    code: `| Name  | Age |<br/>
           | ----- | --- |<br/>
           | Kyle  | 28  |<br/>
           | Sally | 45  |<br/>
           <br/>
           | Right | Center | Left |<br/>
           | ----: | :----: | :--- |<br/>
           | Kyle  | 28     | Hi   |<br/>
           | Sally | 45     | Bye  |`,
    markdown:
      "| Name  | Age |\n| ----- | --- |\n| Kyle  | 28  |\n| Sally | 45  |\n\n" +
      "| Right | Center | Left |\n| ----: | :----: | :--- |\n| Kyle  | 28     | Hi   |\n| Sally | 45     | Bye  |",
    supported: true,
  },
  {
    name: "Headings",
    code: `# Heading 1<br/>
            ## Heading 2<br/>
            ### Heading 3<br/>
            #### Heading 4<br/>
            ##### Heading 5<br/>
            ###### Heading 6`,
    markdown: "# Heading 1\n## Heading 2\n### Heading 3\n#### Heading 4\n##### Heading 5\n###### Heading 6",
    supported: true,
  },
  {
    name: "Paragraphs",
    code: `Will be same<br/>
           paragraph<br/>
           if only one new line used<br/>
           <br/>
           New paragraph if two or more lines are used`,
    markdown: "Will be same\nparagraph\nif only one new line used\n\nNew paragraph if two or more lines are used",
    supported: true,
  },
  {
    name: "Line Breaks",
    code: `Two spaces  <br/>
           at end of line will make a line break`,
    markdown: "Two spaces  \nat the end of a line will make a line break",
    supported: true,
  },
  {
    name: "Links",
    code: `[Go to dashboard](/tree/:id)<br/><br/>
          HOW TO CREATE LINKS: replace ':id' 
          with the id of the page you want to link to.<br/>
          To find the id of any page in the tree, look at the URL in the browser.
          The id will be the number after '/tree/'`,
    markdown: "[Go to dashboard](/tree/0)",
    supported: true,
  },
  {
    name: "Horizontal Rule",
    code: `Needs new lines between<br/><br/>
           ---<br/><br/>
           Can use asterisks or underscores<br/><br/>
           ***<br/><br/>
           Must use at least 3 but can use more<br/><br/>
           --------<br/>`,
    markdown:
      "Needs new lines between\n\n---\n\nCan use asterisks or underscores\n\n***\n\nMust use at least 3 but can use more\n\n--------\n\n",
    supported: true,
  },
];
